import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { def } from '_global/helperGlobal'
import { EncryptHelper } from 'services/EncriptionService'

interface DataRequest {
  requestState?: number | string
  idRequest?: string
  countryId?: string
  typeProviderId?: string
  origin?: string
  formDetails: any
  societiesIds?: number[]
  businessGroupIds?: number[]
}

interface Data {
  continue?: boolean
  statusCode: string
  message: string
  data?: any
}
interface InitialState {
  loading: boolean
  result: Data | null
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: null,
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk(
  'requestInsert/fetch',
  async (data: DataRequest, { rejectWithValue }) => {
    const detailEnc = new EncryptHelper().encryptAESB64(
      filterData(data.formDetails),
      true
    )
    try {
      if (data.formDetails.id !== undefined && data.formDetails.id !== '') {
        const id: string = data.formDetails.id
        return await axios
          .patch(`/request/${id}`, { ...data, formDetails: detailEnc })
          .then((response) => response.data)
      } else {
        return await axios
          .post(`/request`, { ...data, formDetails: detailEnc })
          .then((response) => response.data)
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message)
    }
  }
)

function filterData(formData) {
  if (def(formData.legalPersonBusinessName)) {
    formData.legalPersonBusinessName =
      formData.legalPersonBusinessName.toUpperCase()
  }
  if (def(formData.legalPersonName)) {
    formData.legalPersonName = formData.legalPersonName.toUpperCase()
  }
  if (def(formData.legalPersonSurname1)) {
    formData.legalPersonSurname1 = formData.legalPersonSurname1.toUpperCase()
  }
  if (def(formData.legalPersonSurname2)) {
    formData.legalPersonSurname2 = formData.legalPersonSurname2.toUpperCase()
  }
  if (def(formData.address)) {
    formData.address = formData.address.toUpperCase()
  }
  return formData
}

const requestInsertSlice = createSlice({
  name: 'requestInsert',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<Data>) => {
      state.loading = false
      state.result = action.payload
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = null
      let msg = ''
      if (typeof action.payload === 'string') {
        msg = action.payload
      } else if (Array.isArray(action.payload)) {
        msg = action.payload.join(', ')
      } else {
        msg = action.error.message ?? 'Something went wrong'
      }
      state.error = msg
    })
  }
})

export default requestInsertSlice.reducer
