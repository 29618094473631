import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

interface Data {
  statusCode: string
  message: string
  data?: any
}
interface InitialState {
  loading: boolean
  result: Data | null
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: null,
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk(
  'requestCancel/fetch',
  async (
    {
      id,
      comment
    }: {
      id: number
      comment: string
    },
    { rejectWithValue }
  ) => {
    try {
      return await axios
        .patch(`/request/cancelRequest/${id}`, {
          requestState: '32',
          comments: comment
        })
        .then((response) => response.data)
    } catch (error) {
      return rejectWithValue(error.response.data.message)
    }
  }
)

const requestCancelSlice = createSlice({
  name: 'requestCancel',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<Data>) => {
      state.loading = false
      state.result = action.payload
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = null
      let msg = ''
      if (typeof action.payload === 'string') {
        msg = action.payload
      } else if (Array.isArray(action.payload)) {
        msg = action.payload.join(', ')
      } else {
        msg = action.error.message ?? 'Something went wrong'
      }
      state.error = msg
    })
  }
})

export default requestCancelSlice.reducer
