import { PermissionData } from '_redux/slices/userRolSlice'

import i18next from 'i18next'

export const capitalizeFirstLetter = (text: string) => {
  return text.charAt(0).toUpperCase() + text.slice(1)
}

export function formatTaxId(taxId: string, country: string): string {
  switch (country) {
    case 'CL':
      taxId = taxId.replace(/[^kK0-9-]/g, '')
      break
    case 'CO':
      taxId = taxId.replace(/[^0-9-]/g, '')
      break
    default:
      taxId = taxId.replace(/[^a-zA-Z0-9-]/g, '')
  }

  return taxId
}

// Chile RUT
export function checkCL1(rut: string) {
  if (rut.substring(rut.length - 2, rut.length - 1) !== '-') {
    return false
  }
  const rut2 = rut.substring(0, rut.length - 1).replace('-', '')
  const dv = rut.substring(rut.length - 1)
  const num = rut2.split('').reverse()
  let ac = 0
  let mult = 2
  for (const n of num) {
    ac += parseInt(n) * mult
    mult++
    if (mult === 8) {
      mult = 2
    }
  }
  let dvv = 11 - (ac % 11)
  if (dvv === 11) {
    dvv = 0
  }
  return dvv === Number(dv) || (dvv === 10 && dv.toLowerCase() === 'k')
}

// Peru RUC
export function checkPE1(ruc: string): boolean {
  if (!/^\d{11}$/.test(ruc)) {
    return false // Debe tener 11 dígitos
  }

  const primerDigito = parseInt(ruc.charAt(0))
  if (primerDigito !== 1 && primerDigito !== 2) {
    return false // El primer dígito debe ser 1 o 2
  }

  const coeficientes = [5, 4, 3, 2, 7, 6, 5, 4, 3, 2]
  let suma = 0

  for (let i = 0; i < 10; i++) {
    suma += parseInt(ruc.charAt(i)) * coeficientes[i]
  }

  const resto = suma % 11
  const digitoVerificador = 11 - resto

  if (digitoVerificador === 10) {
    return parseInt(ruc.charAt(10)) === 0
  } else if (digitoVerificador === 11) {
    return parseInt(ruc.charAt(10)) === 1
  } else {
    return parseInt(ruc.charAt(10)) === digitoVerificador
  }
}

export function checkPE1Old3(ruc: string) {
  return ruc.replace(/\D+/g, '').length === 11
}

export function checkPE1Old2(ruc: number) {
  // 11 dígitos y empieza en 10,15,16,17 o 20
  const rruc = ruc
  if (
    !(
      (ruc >= 1e10 && ruc < 11e9) ||
      (ruc >= 15e9 && ruc < 18e9) ||
      (ruc >= 2e10 && ruc < 21e9)
    )
  )
    return false

  // eslint-disable-next-line no-var
  for (var suma = -(ruc % 10 < 2), i = 0; i < 11; i++, ruc = (ruc / 10) | 0)
    suma += (ruc % 10) * ((i % 7) + ((i / 7) | 0) + 1)

  const div = 11 - (suma - Math.floor(suma / 11) * 11)
  return div % 11 === 0 || div.toString() === rruc.toString().slice(-1)
}

// Peru RUC
export function checkPE1Old(ruc: number) {
  if (ruc === Number(ruc) && ruc % 1 === 0 && rucValid(ruc)) {
    return true
  } else {
    return false
  }
}

function rucValid(ruc) {
  const rruc = ruc
  if (
    !(
      (ruc >= 1e10 && ruc < 11e9) ||
      (ruc >= 15e9 && ruc < 18e9) ||
      (ruc >= 2e10 && ruc < 21e9)
    )
  )
    return false

  // eslint-disable-next-line no-var
  for (var suma = -(ruc % 10 < 2), i = 0; i < 11; i++, ruc = (ruc / 10) | 0)
    suma += (ruc % 10) * ((i % 7) + ((i / 7) | 0) + 1)

  const div = 11 - (suma - Math.floor(suma / 11) * 11)
  return div % 11 === 0 || div.toString() === rruc.toString().substring(-1)
}

// Peru DNI
export function checkPE2(dni: string) {
  const exRegularDni = /^\d{8}(?:[-\s]\d{4})?$/
  if (exRegularDni.test(dni)) {
    return true
  } else {
    return false
  }
}

/*
function esnumero(dato) {
  return !isNaN(dato)
}

export function checkPE2(valor: string) {
  valor = valor.trim()
  let suma, x, resto, digito, i
  if (esnumero(valor)) {
    if (valor.length === 8) {
      suma = 0
      for (i = 0; i < valor.length - 1; i++) {
        const digito = valor.charAt(i) - '0'
        if (i == 0) suma += digito * 2
        else suma += digito * (valor.length - i)
      }
      resto = suma % 11
      if (resto == 1) resto = 11
      if (resto + (valor.charAt(valor.length - 1) - '0') == 11) {
        return true
      }
    } else if (valor.length == 11) {
      suma = 0
      x = 6
      for (i = 0; i < valor.length - 1; i++) {
        if (i == 4) x = 8
        digito = valor.charAt(i) - '0'
        x--
        if (i == 0) suma += digito * x
        else suma += digito * x
      }
      resto = suma % 11
      resto = 11 - resto

      if (resto >= 10) resto = resto - 10
      if (resto == valor.charAt(valor.length - 1) - '0') {
        return true
      }
    }
  }
  return false
}
*/

export function checkPE2Old(dni: string) {
  const num = dni.substring(0, dni.length - 1).replace(/\D/g, '')
  const dv = dni.substring(dni.length - 1)
  return checkDni(num, dv)
}

export function checkPE2New(dni: string) {
  let numero, lett, letra
  // eslint-disable-next-line @typescript-eslint/naming-convention
  const expresion_regular_dni = /^[XYZ]?\d{5,8}[A-Z]$/

  dni = dni.toUpperCase()

  if (expresion_regular_dni.test(dni)) {
    numero = dni.substr(0, dni.length - 1)
    numero = numero.replace('X', 0)
    numero = numero.replace('Y', 1)
    numero = numero.replace('Z', 2)
    lett = dni.substr(dni.length - 1, 1)
    numero = numero % 23
    letra = 'TRWAGMYFPDXBNJZSQVHLCKET'
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    letra = letra.substring(numero, numero + 1)
    if (letra !== lett) {
      // alert('Dni erroneo, la letra del NIF no se corresponde');
      return false
    } else {
      // alert('Dni correcto');
      return true
    }
  } else {
    // alert('Dni erroneo, formato no válido');
    return false
  }
}

function checkDni(dni, cchar): boolean {
  const numberKeys = [6, 7, 8, 9, 0, 1, 1, 2, 3, 4, 5]
  const charKeys = ['K', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J']

  const factors = [3, 2, 7, 6, 5, 4, 3, 2]
  const dniDigits = dni
    .trim()
    .split('')
    .map((x) => parseInt(x, 10))
  const sum = dniDigits.reduce((sum, x, i) => {
    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    sum += factors[i] * x
    return sum
  }, 0)
  let keyIndex = 11 - (sum % 11)
  keyIndex = keyIndex === 11 ? 0 : keyIndex

  // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
  const control = parseInt(cchar, 10) || cchar.toUpperCase()

  if (isNaN(control)) {
    return control === charKeys[keyIndex]
  } else {
    return control === numberKeys[keyIndex]
  }
}

// Colombia
export function checkCO1(myNit: string) {
  if (myNit !== undefined && myNit !== '') {
    const num = myNit.substring(0, myNit.length - 1).replace(/\D/g, '')
    const dv = myNit.substring(myNit.length - 1)
    const dvV = nitCo(num)
    return Number(dv) === dvV
  } else {
    return false
  }
}

function nitCo(myNit) {
  let vpri, x, y, z

  // Se limpia el Nit
  myNit = myNit.replace(/\s/g, '') // Espacios
  myNit = myNit.replace(/,/g, '') // Comas
  myNit = myNit.replace(/\./g, '') // Puntos
  myNit = myNit.replace(/-/g, '') // Guiones

  // Se valida el nit
  if (isNaN(myNit)) {
    return ''
  }

  // Procedimiento
  // eslint-disable-next-line prefer-const
  vpri = new Array(16)
  // eslint-disable-next-line prefer-const
  z = myNit.length

  vpri[1] = 3
  vpri[2] = 7
  vpri[3] = 13
  vpri[4] = 17
  vpri[5] = 19
  vpri[6] = 23
  vpri[7] = 29
  vpri[8] = 37
  vpri[9] = 41
  vpri[10] = 43
  vpri[11] = 47
  vpri[12] = 53
  vpri[13] = 59
  vpri[14] = 67
  vpri[15] = 71

  x = 0
  y = 0
  for (let i = 0; i < z; i++) {
    y = myNit.substr(i, 1)
    // console.log ( y + "x" + vpri[z-i] + ":" ) ;

    // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
    x += y * vpri[z - i]
    // console.log ( x ) ;
  }

  y = x % 11
  // console.log ( y ) ;

  return y > 1 ? 11 - y : y
}

export function checkCO2(cc: string) {
  return (
    cc != null &&
    cc !== '' &&
    !isNaN(Number(cc.toString())) &&
    cc.toString().length <= 10 &&
    cc.toString().length >= 3
  )
}

export function checkCO2Old(cc: any) {
  return cc.replace(/\D+/g, '').length === 10
}

export function def(elem: any) {
  return elem !== undefined && elem !== ''
}
export function defArray(elem: any) {
  return elem !== undefined && Array.isArray(elem) && elem.length > 0
}

export function defNum(elem: any) {
  return elem !== undefined && elem > 0
}

export function getCountryProvider(countryApplicant, origin, countryProvider) {
  if (
    countryProvider !== undefined &&
    countryProvider > 0 &&
    origin === 'EXT'
  ) {
    return countryProvider
  } else if (
    countryApplicant !== undefined &&
    countryApplicant > 0 &&
    origin !== undefined &&
    origin === 'NAC'
  ) {
    return countryApplicant
  } else {
    return 0
  }
}

export const checkEmail = (email) => {
  return (
    String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      ) !== null
  )
}

export const checkPhone = (value) => {
  return (
    String(value)
      .toLowerCase()
      .match(/^(\d+-?)+\d+$/) !== null
  )
}

export const getPermission = (
  path: string,
  permission: PermissionData[]
): PermissionData => {
  if (permission.length > 0) {
    let tempExistPath: PermissionData[] = []
    tempExistPath = permission.filter(
      (item) =>
        def(path) &&
        path?.substring(0, item.path.length).toLowerCase() ===
          item.path.toLowerCase()
    )
    return tempExistPath[0]
  } else {
    return {
      path: '',
      view: false,
      create: false,
      edit: false,
      delete: false,
      viewDocs: false
    }
  }
}

export const sizeFriendly = (size: number): string => {
  const fSExt = ['Bytes', 'KB', 'MB', 'GB']
  let i = 0
  while (size > 900) {
    size /= 5120
    i++
  }
  const exactSize = (Math.round(size * 100) / 100).toString() + ' ' + fSExt[i]
  return exactSize
}

export const getRequestId = (id: string): number => {
  if (id.includes('-')) {
    const part = id.split('-')
    return Number(part[0])
  } else {
    return Number(id)
  }
}

export const filterData = (
  data: any,
  field: string[],
  filterText: string
): any => {
  if (filterText !== '') {
    const fieldFind = field.find(
      (item) =>
        data[item] !== undefined &&
        data[item] !== null &&
        data[item] !== '' &&
        data[item].toString().toLowerCase().indexOf(filterText.toLowerCase()) >
          -1 &&
        item
    )
    if (fieldFind !== undefined) {
      return data
    }
  } else {
    return data
  }
}

export const getTodayString = () => {
  const today = new Date()
  return `${today.toISOString().split('T')[0].split('-').join('')}_${today
    .toISOString()
    .split('T')[1]
    .split('.')[0]
    .split(':')
    .join('')}`
}

export const sanitizeRut = (rut: string) => {
  rut = rut.toUpperCase()
  return rut.replace(/[^0-9-K]/g, '')
}

export const sanitizeSpace = (id: string) => {
  return id.replaceAll(' ', '')
}

export const getLabelLang = (
  field: any,
  fieldId: string,
  lang: string = i18next.language
): string => {
  if (
    field[fieldId] !== undefined &&
    field[fieldId] !== null &&
    field[fieldId] !== ''
  ) {
    if (lang === 'en') {
      if (
        field[fieldId + '_en'] !== undefined &&
        field[fieldId + '_en'] !== null &&
        field[fieldId + '_en'] !== ''
      ) {
        return field[fieldId + '_en']
      } else {
        return String(field[fieldId]) + ' (en)'
      }
    } else {
      return field[fieldId]
    }
  } else {
    return ''
  }
}
