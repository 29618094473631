import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { def } from '_global/helperGlobal'
import { EncryptHelper } from 'services/EncriptionService'

import { FormData } from 'types/FormData'
import { Data as DataForm } from '_redux/slices/formSlice'

interface InitialState {
  loading: boolean
  result: { form: DataForm[]; request: FormData } // DataForm | null
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: { form: [], request: {} },
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk(
  'requestHistory/fetch',
  async (requestId: string) => {
    const endpoint = 'form-history'
    return await axios
      .get(`/${endpoint}/${requestId}`)
      .then((response) => response.data.data)
  }
)

const requestHistorySlice = createSlice({
  name: 'requestHistory',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<any>) => {
      const pl = action.payload

      if (
        pl !== undefined &&
        Object.keys(pl).length > 0 &&
        pl?.formDetails !== ''
        // Object.keys(pl?.formDetails).length > 0
      ) {
        const form: DataForm[] = Object.keys(pl).map(
          (item) =>
            item !== 'formDetails' &&
            pl[item]?.form !== undefined &&
            pl[item].form
        )
        const request: FormData = new EncryptHelper().decryptAESB64Json(
          pl.formDetails
        )
        state.loading = false
        state.result = {
          form: form.filter((item) => def(item?.id)),
          request
        }
        state.error = ''
      } else {
        state.loading = false
        state.result = initialState.result
        state.error = 'No existe la solicitud'
      }
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = initialState.result
      state.error = action.error.message ?? 'Something went wrong'
    })
  }
})

export default requestHistorySlice.reducer

export const { reset } = requestHistorySlice.actions
