import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

export interface Data {
  id?: number
  countryIds: number[]
  typeProdiverIds: number[]
  businessGroupId: string
  societyIds: number[]
  ruleId: string
  ruleDescription: string
  ruleActive: boolean
  origin: string
}
interface InitialState {
  loading: boolean
  result: Data[]
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: [],
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk('rule/fetch', async () => {
  return await axios.get('/rules').then((response) => response.data.data)
})

const ruleSlice = createSlice({
  name: 'rule',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<Data[]>) => {
      state.loading = false
      state.result = action.payload
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = []
      state.error = action.error.message ?? 'Something went wrong'
    })
  }
})

export default ruleSlice.reducer
