import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

const initialState: any = {
  locale: null
}

// Acción asincrónica para obtener el estado inicial de profile desde la API
export const fetchProfile = createAsyncThunk(
  'profile/fetchProfile',
  async () => {
    const response = await axios.get('/auth/profile')
    return { locale: response.data.locale }
  }
)

const profileSlice = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setLocale: (state, action) => {
      state.locale = action.payload
    }
    // Otras acciones para modificar el perfil
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProfile.fulfilled, (state, action) => {
      // Actualizar el estado inicial con los datos obtenidos de la API
      return { ...state, ...action.payload }
    })
  }
})

export const { setLocale } = profileSlice.actions
export default profileSlice.reducer
