import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  loading: boolean
  result: any // DataForm | null
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: {},
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk(
  'providerRequest/fetch',
  async (providerId: number) => {
    return await axios
      .get(`/request/byUserRole/list/?idprovider=${providerId.toString()}`)
      .then((response) => response.data.data)
  }
)

const providerRequestSlice = createSlice({
  name: 'providerRequest',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<any>) => {
      const pl = action.payload
      state.loading = false
      state.result = pl
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = initialState.result
      state.error = action.error.message ?? 'Something went wrong'
    })
  }
})

export default providerRequestSlice.reducer

export const { reset } = providerRequestSlice.actions
