import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { EncryptHelper } from 'services/EncriptionService'

// import { Data as DataForm } from '_redux/slices/formSlice'

interface DataRequest {
  requestId?: string
  requestState?: number
}

interface InitialState {
  loading: boolean
  result: any // DataForm | null
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: null,
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk(
  'formRequest/fetch',
  async (data: DataRequest) => {
    if (data.requestId !== undefined && data.requestId !== '') {
      const endpoint = 'request'
      return await axios
        .get(`/${endpoint}/${data.requestId}`)
        .then((response) => response.data.data ?? response.data)
    } else {
      return await axios
        .post('/forms/fullDetail/', {
          requestState: data.requestState,
          countryId: 0,
          typeProviderId: 0,
          businessGroupId: 0,
          societies: ['string'],
          origin: 'string'
        })
        .then((response) => response.data.data.form)
    }
  }
)

const formRequestSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<any>) => {
      const pl = action.payload
      let formDetail
      let result = {}
      if (pl.formDetails !== undefined) {
        result = {
          ...result,
          form: pl.formDetails,
          data: { requestState: 1 }
        }
      }
      if (
        pl.reqData?.formDetails !== undefined &&
        pl.nextFormData?.form !== undefined &&
        pl.nextFormData?.form !== null &&
        pl.nextFormData?.form.requestState !== undefined &&
        pl.nextFormData?.form.formDetails !== undefined
      ) {
        formDetail = pl.reqData.formDetails
        formDetail = new EncryptHelper().decryptAESB64Json(formDetail)
        result = {
          ...result,
          data: {
            ...formDetail,
            requestState: pl.nextFormData?.form.requestState,
            id: pl.reqData.id,
            idRequest: pl.reqData.idRequest
          },
          form: pl.nextFormData?.form.formDetails,
          pl
        }
      }
      if (Object.keys(result).length === 0) {
        state.loading = false
        state.result = null
        state.error = pl.message
      } else {
        state.loading = false
        state.result = result
        state.error = ''
      }
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = null
      state.error = action.error.message ?? 'Something went wrong'
    })
  }
})

export default formRequestSlice.reducer
