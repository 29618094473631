import i18n from 'i18next'
import detector from 'i18next-browser-languagedetector'
import backend from 'i18next-http-backend'
import { initReactI18next } from 'react-i18next'
import translationEN from './assets/i18n/en.json'
import translationES from './assets/i18n/es.json'

const resources = {
  en: {
    translation: translationEN
  },
  es: {
    translation: translationES
  }
}
// translations are already at
// '../public/locales/en/translation.json'
// which is the default for the xhr backend to load from

i18n
  .use(detector)
  .use(backend)
  .use(initReactI18next)
  .init({
    resources,
    supportedLngs: ['es', 'en'],
    lng: 'es',
    fallbackLng: 'es', // use en if detected lng is not available
    detection: {
      order: [
        'querystring',
        'cookie',
        'localStorage',
        'sessionStorage',
        'navigator',
        'htmlTag',
        'path',
        'subdomain'
      ],
      caches: ['cookie']
    },
    backend: { loadPath: '/src/assets/i18n/{{lng}}.json' },
    debug: false,
    react: {
      wait: true
    }
  })

export default i18n
