import React, { Suspense } from 'react'
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom'
import { ThemeProvider, createTheme } from '@mui/material/styles'

import './App.css'

import Col from './_global/color'

const theme = createTheme({
  palette: {
    primary: {
      main: Col.identityBlue
    },
    background: {
      default: Col.greyMin
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorPrimary: {
          backgroundColor: Col.identityPrimary
        }
      }
    }
  }
})

const Home = React.lazy(async () => await import('./pages/home/Home'))
const DefaultLayout = React.lazy(
  async () => await import('./layout/DefaultLayout')
)

const loading = <div className="pt-3 text-center"></div>

const Theme: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <BrowserRouter>
        <Suspense fallback={loading}>
          <Routes>
            <Route path="/home" element={<Home />} />
            <Route path="*" element={<DefaultLayout />} />
            <Route path="*" element={<Navigate to="/home" replace />} />
          </Routes>
        </Suspense>
      </BrowserRouter>
    </ThemeProvider>
  )
}

export default Theme
