import axios from '_global/axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

export interface Data {
  id?: number
  fieldId: string
  fieldType: string
  catalogId: string
  fieldLabel: string
  fieldLabelEn?: string
  fieldPlaceholder: string
  fieldPlaceholderEn?: string
  fieldLength: number
  fieldHint: string
  fieldHintEn?: string
  fieldSapId: string
}
interface InitialState {
  loading: boolean
  result: Data[]
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: [],
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk('field/fetch', async () => {
  return await axios.get('/fields').then((response) => response.data.data)
})

const fieldSlice = createSlice({
  name: 'field',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<Data[]>) => {
      state.loading = false
      state.result = action.payload
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = []
      state.error = action.error.message ?? 'Something went wrong'
    })
  }
})

export default fieldSlice.reducer
