import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

interface InitialState {
  loading: boolean
  result: any
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: [],
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk(
  'approvalChecker/fetch',
  async (id: number, { rejectWithValue }) => {
    if (isNaN(id)) return rejectWithValue('Invalid id')
    try {
      return await axios
        .get('/approvals/checker/' + id.toString())
        .then((response) => response.data)
    } catch (error) {
      console.log(error)
      return rejectWithValue(error.response.data.message)
    }
  }
)

const approvalCheckerSlice = createSlice({
  name: 'approvalChecker',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<any>) => {
      const pl = action.payload
      if (pl.data !== undefined) {
        state.loading = false
        state.result = pl.data
        state.error = ''
      } else {
        state.loading = false
        state.result = []
        state.error = ''
      }
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = []
      let msg = ''
      if (typeof action.payload === 'string') {
        msg = action.payload
      } else if (Array.isArray(action.payload)) {
        msg = action.payload.join(', ')
      } else {
        msg = action.error.message ?? 'Something went wrong'
      }
      state.error = msg
    })
  }
})

export default approvalCheckerSlice.reducer

export const { reset } = approvalCheckerSlice.actions
