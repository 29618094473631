const Col = {
  identityPrimary: '#3C4149',
  identityBlue: '#274AB6',

  grey: '#B6B8BD',
  greyMin: '#E7EAF0',

  green: '#17A600'
}

export default Col
