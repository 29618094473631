import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

export interface DataRequest {
  id?: string
  taxId?: string
  typeTaxId?: number
  countryId?: number
  typeProviderId?: number
  formDetails?: any
  ABC?: string
  providerStatus?: string
  expiredAt?: string

  taxidsd?: string
  niftaxidvalue?: string
  providerName?: string
  niftaxidtype?: string
}

interface Data {
  statusCode: string
  message: string
  data?: any
}
interface InitialState {
  loading: boolean
  result: Data | null
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: null,
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk(
  'providerEdit/fetch',
  async (data: DataRequest, { rejectWithValue }) => {
    try {
      if (data.id !== undefined && data.id !== '') {
        const id: string = data.id
        delete data.id
        return await axios
          .patch(`/providers/${id}`, data)
          .then((response) => response.data)
      } else {
        delete data.id
        return await axios
          .post(`/providers`, data)
          .then((response) => response.data)
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message)
    }
  }
)

const providerEditSlice = createSlice({
  name: 'providerEdit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<Data>) => {
      state.loading = false
      state.result = action.payload
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = null
      let msg = ''
      if (typeof action.payload === 'string') {
        msg = action.payload
      } else if (Array.isArray(action.payload)) {
        msg = action.payload.join(', ')
      } else {
        msg = action.error.message ?? 'Something went wrong'
      }
      state.error = msg
    })
  }
})

export default providerEditSlice.reducer
