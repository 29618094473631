import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { FormField } from 'types/FormField'

export interface Data {
  id?: number
  requestState: number
  formId: string
  formDescription: string
  formDescription_en: string
  formDetails: FormField[]
  formActive: boolean
}
interface InitialState {
  loading: boolean
  result: Data[]
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: [],
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk('form/fetch', async () => {
  return await axios.get('/forms').then((response) => response.data.data)
})

const formSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<Data[]>) => {
      state.loading = false
      const obj = action.payload
      const result = Object.entries(obj).map(([name, obj]) => ({
        name,
        ...obj
      }))
      state.result = result
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = []
      state.error = action.error.message ?? 'Something went wrong'
    })
  }
})

export default formSlice.reducer
