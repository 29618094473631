import * as CryptoJS from 'crypto-js'
import { Data as DataForm } from '_redux/slices/formSlice'

const encryptionKey = process.env.REACT_APP_ENCRYPTION_KEY ?? ''
const iV = process.env.REACT_APP_IV ?? ''

export class EncryptHelper {
  encryptAESB64(code: any, json: boolean = false): string {
    console.log('code:', code)
    const key = CryptoJS.enc.Utf8.parse(encryptionKey)
    const iv = CryptoJS.enc.Utf8.parse(iV)
    const data = CryptoJS.enc.Utf8.parse(json ? JSON.stringify(code) : code)
    const encodeAES = CryptoJS.AES.encrypt(data, key, { iv })
    const e64 = encodeAES.ciphertext.toString(CryptoJS.enc.Base64)
    console.log(e64)
    return e64
  }

  decryptAESB64(code: string): string {
    try {
      const key = CryptoJS.enc.Utf8.parse(encryptionKey)
      const iv = CryptoJS.enc.Utf8.parse(iV)
      const data = CryptoJS.enc.Base64.parse(code)
      const b64 = data.toString(CryptoJS.enc.Base64)
      const decoded = CryptoJS.AES.decrypt(b64, key, { iv })
      const decodedString = CryptoJS.enc.Utf8.stringify(decoded)
      return decodedString
    } catch (e) {
      return ''
    }
  }

  decryptAESB64Json(code: string): DataForm {
    const r = this.decryptAESB64(code)
    return JSON.parse(r)
  }
}
