import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import { EncryptHelper } from 'services/EncriptionService'

interface InitialState {
  loading: boolean
  result: any // DataForm | null
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: null,
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk(
  'requestData/fetch',
  async (requestId: string) => {
    const endpoint = 'request'
    return await axios
      .get(`/${endpoint}/${requestId}`)
      .then((response) => response.data.data)
  }
)

const requestDataSlice = createSlice({
  name: 'requestData',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<any>) => {
      const pl = action.payload
      // console.log(JSON.stringify(pl.reqData.formDetails))
      let result = {}
      if (pl?.reqData?.formDetails !== undefined) {
        result = new EncryptHelper().decryptAESB64Json(pl.reqData.formDetails)
      }
      if (Object.keys(result).length === 0) {
        state.loading = false
        state.result = null
        state.error = pl?.message ?? 'No existe la solicitud'
      } else {
        state.loading = false
        state.result = result
        state.error = ''
      }
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = null
      state.error = action.error.message ?? 'Something went wrong'
    })
  }
})

export default requestDataSlice.reducer

export const { reset } = requestDataSlice.actions
