import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

export interface DocApproval {
  [key: string]: DocApprovalElem
}

export interface DocApprovalElem {
  id: string
  label: string
  label_en?: string
  state: boolean | null
}

interface DataRequest {
  id?: string
  requestId: number
  fileDetails: DocApproval
}

interface Data {
  statusCode: string
  message: string
  data?: any
}
interface InitialState {
  loading: boolean
  result: Data | null
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: null,
  error: ''
}

export const getDocApprovalState = (
  docApproval: DocApproval,
  id: string
): boolean | null => {
  if (docApproval[id] !== undefined) {
    return docApproval[id].state
  } else {
    return null
  }
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk(
  'formFileEdit/fetch',
  async (data: DataRequest, { rejectWithValue }) => {
    try {
      if (data.id !== undefined && data.id !== '') {
        const id: string = data.id
        return await axios
          .patch(`/form-file/${id}`, data)
          .then((response) => response.data)
      } else {
        delete data.id
        return await axios
          .post(`/form-file`, data)
          .then((response) => response.data)
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message)
    }
  }
)

const formFileEditSlice = createSlice({
  name: 'formFileEdit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<Data>) => {
      state.loading = false
      state.result = action.payload
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = null
      let msg = ''
      if (typeof action.payload === 'string') {
        msg = action.payload
      } else if (Array.isArray(action.payload)) {
        msg = action.payload.join(', ')
      } else {
        msg = action.error.message ?? 'Something went wrong'
      }
      state.error = msg
    })
  }
})

export default formFileEditSlice.reducer
