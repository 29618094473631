/* eslint-disable @typescript-eslint/prefer-optional-chain */
/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/return-await */
import React, { createContext, useState, useEffect, useContext } from 'react'
import { path } from 'ramda'
import { useKeycloak } from '@react-keycloak/web'
import Theme from '../Theme'

import { login, profile } from '../services/AuthService'
import { useAppDispatch } from '_redux/hooks'
import { fetchProfile } from '_redux/slices/profileSlice'
const UserContext = createContext({})

const UserProvider: React.FC = () => {
  const [user, setUser] = useState({})
  const [userError, setUserError] = useState('')
  const dispatch = useAppDispatch()
  const { keycloak } = useKeycloak()
  const getUserSession = (username, password) => {
    login({ username, password })
      .then((t) => {
        const token = path(['data', 'access_token'], t)
        if (token) {
          window['userToken'] = token
          dispatch(fetchProfile())
          profile().then(({ data }) => {
            if (data && data.id) {
              setUser(data)
              setUserError('')
            }
          })
        }
      })
      .catch((e) => {
        console.log('error login', e.message)
        setUser({})
        setUserError(
          'No tienes permiso para acceder a este sitio, favor contáctate con un administrador.'
        )
      })
  }

  useEffect(() => {
    keycloak.loadUserInfo().then((userInfo: any) => {
      getUserSession(userInfo.email, userInfo.sub)
    })
    // eslint-disable-next-line
  }, [])
  return (
    <UserContext.Provider
      value={{
        user,
        userError
      }}
    >
      <Theme />
    </UserContext.Provider>
  )
}

export default UserProvider

export const useUser: any = () => {
  const context = useContext(UserContext)
  if (context === undefined) {
    throw new Error(`useUser must be used within a UserContextProvider.`)
  }
  return context
}
