import { configureStore } from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { combineReducers } from 'redux'
import { persistReducer } from 'redux-persist'

import providerTypeReducer from '_redux/slices/providerTypeSlice'
import businessGroupReducer from '_redux/slices/businessGroupSlice'
import catalogueEditReducer from '_redux/slices/catalogueEditSlice'
import countryReducer from '_redux/slices/countrySlice'
import regionReducer from '_redux/slices/regionSlice'
import taxReducer from '_redux/slices/taxSlice'
import paymentConditionReducer from '_redux/slices/paymentConditionSlice'
import economicActivityReducer from '_redux/slices/economicActivitySlice'
import businessPartnerReducer from '_redux/slices/businessPartnerSlice'
import bankReducer from '_redux/slices/bankSlice'
import censusReducer from '_redux/slices/censusSlice'
import taxClassReducer from '_redux/slices/taxClassSlice'
import currencyReducer from '_redux/slices/currencySlice'
import accountTypeReducer from '_redux/slices/accountTypeSlice'
import retentionTypeReducer from '_redux/slices/retentionTypeSlice'
import indicatorRetentionReducer from '_redux/slices/indicatorRetentionSlice'
import indicatorRetentionEditReducer from '_redux/slices/indicatorRetentionEditSlice'
import requestStatusReducer from '_redux/slices/requestStatusSlice'
import fieldReducer from '_redux/slices/fieldSlice'
import fieldEditReducer from '_redux/slices/fieldEditSlice'
import cityReducer from '_redux/slices/citySlice'
import cityEditReducer from '_redux/slices/cityEditSlice'
import countyReducer from '_redux/slices/countySlice'
import countyEditReducer from '_redux/slices/countyEditSlice'
import catalogueReducer from '_redux/slices/catalogueSlice'
import societyReducer from '_redux/slices/societySlice'
import societyEditReducer from '_redux/slices/societyEditSlice'
import roleReducer from '_redux/slices/roleSlice'
import ruleReducer from '_redux/slices/ruleSlice'
import ruleEditReducer from '_redux/slices/ruleEditSlice'
import formReducer from '_redux/slices/formSlice'
import formGetReducer from '_redux/slices/formGetSlice'
import formEditReducer from '_redux/slices/formEditSlice'
import formRequestReducer from '_redux/slices/formRequestSlice'
import requestInsertReducer from '_redux/slices/requestInsertSlice'
import requestCancelReducer from '_redux/slices/requestCancelSlice'
import permissionReducer from '_redux/slices/permissionSlice'
import permissionEditReducer from '_redux/slices/permissionEditSlice'
import userRolReducer from '_redux/slices/userRolSlice'
import profileReducer from '_redux/slices/profileSlice'
import formFileEditReducer from '_redux/slices/formFileEditSlice'
import formFileGetReducer from '_redux/slices/formFileGetSlice'
import requestDataReducer from '_redux/slices/requestDataSlice'
import requestHistoryReducer from '_redux/slices/requestHistorySlice'
import requestInfoReducer from '_redux/slices/requestInfoSlice'
import approvalCheckerReducer from '_redux/slices/approvalCheckerSlice'
import subStateReducer from '_redux/slices/subStateSlice'
import reasonRequestReducer from '_redux/slices/reasonRequestSlice'
import typeObservationReducer from '_redux/slices/typeObservationSlice'
import providerEditReducer from '_redux/slices/providerEditSlice'
import providerRequestReducer from '_redux/slices/providerRequestSlice'
import bankDetailReducer from '_redux/slices/bankDetailSlice'
import bankDetailEditReducer from '_redux/slices/bankDetailEditSlice'
import temporaryApprovalsEditReducer from '_redux/slices/temporaryApprovalsEditSlice'
import templateFileReducer from '_redux/slices/templateFileSlice'
import templateFileEditReducer from '_redux/slices/templateFileEditSlice'
import tiCriteriaReducer from '_redux/slices/tiCriteriaSlice'
import providerAbcReducer from '_redux/slices/providerAbcSlice'
import providerAbcEditReducer from '_redux/slices/providerAbcEditSlice'
import approvalEditReducer from '_redux/slices/approvalEditSlice'
import getLastRequestReducer from '_redux/slices/getLastRequestSlice'

const reducers = combineReducers({
  providerType: providerTypeReducer,
  businessGroup: businessGroupReducer,
  catalogueEdit: catalogueEditReducer,
  country: countryReducer,
  region: regionReducer,
  tax: taxReducer,
  paymentCondition: paymentConditionReducer,
  economicActivity: economicActivityReducer,
  businessPartner: businessPartnerReducer,
  bank: bankReducer,
  census: censusReducer,
  taxClass: taxClassReducer,
  currency: currencyReducer,
  accountType: accountTypeReducer,
  retentionType: retentionTypeReducer,
  indicatorRetention: indicatorRetentionReducer,
  indicatorRetentionEdit: indicatorRetentionEditReducer,
  requestStatus: requestStatusReducer,
  field: fieldReducer,
  fieldEdit: fieldEditReducer,
  catalogue: catalogueReducer,
  city: cityReducer,
  cityEdit: cityEditReducer,
  county: countyReducer,
  countyEdit: countyEditReducer,
  rule: ruleReducer,
  ruleEdit: ruleEditReducer,
  society: societyReducer,
  societyEdit: societyEditReducer,
  form: formReducer,
  formGet: formGetReducer,
  formEdit: formEditReducer,
  formRequest: formRequestReducer,
  requestInsert: requestInsertReducer,
  requestCancel: requestCancelReducer,
  role: roleReducer,
  permission: permissionReducer,
  permissionEdit: permissionEditReducer,
  userRol: userRolReducer,
  profile: profileReducer,
  requestData: requestDataReducer,
  requestHistory: requestHistoryReducer,
  requestInfo: requestInfoReducer,
  formFileEdit: formFileEditReducer,
  formFileGet: formFileGetReducer,
  approvalChecker: approvalCheckerReducer,
  subState: subStateReducer,
  reasonRequest: reasonRequestReducer,
  typeObservation: typeObservationReducer,
  providerEdit: providerEditReducer,
  providerRequest: providerRequestReducer,
  bankDetail: bankDetailReducer,
  bankDetailEdit: bankDetailEditReducer,
  temporaryApprovalsEdit: temporaryApprovalsEditReducer,
  templateFile: templateFileReducer,
  templateFileEdit: templateFileEditReducer,
  tiCriteria: tiCriteriaReducer,
  providerAbc: providerAbcReducer,
  providerAbcEdit: providerAbcEditReducer,
  approvalEdit: approvalEditReducer,
  getLastRequest: getLastRequestReducer
})

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['userRol', 'profile']
}

const persistedReducer = persistReducer(persistConfig, reducers)

const store = configureStore({
  reducer: persistedReducer,
  devTools: process.env.NODE_ENV !== 'production'
  // middleware: [thunkMiddleware]
})

export default store
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch
