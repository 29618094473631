import axios from '_global/axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
export interface Data {
  id: number
  reasonRequest: number
  description: string
  stateId: number
  subStateId: number
  dm: boolean
  rulesIds: number[]
  temp: boolean
}
interface InitialState {
  loading: boolean
  result: Data[]
  resultKey: { [key: string]: Data }
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: [],
  resultKey: {},
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk('reasonRequest/fetch', async () => {
  return await axios
    .get('/reasons-requests')
    .then((response) => response.data.data)
})

const reasonRequestSlice = createSlice({
  name: 'reasonRequest',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<any>) => {
      state.loading = false
      state.result = action.payload
      const temp: { [key: string]: Data } = {}
      action.payload.map((elem) => (temp[elem.id] = elem))
      state.resultKey = temp
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = []
      state.error = action.error.message ?? 'Something went wrong'
    })
  }
})

export default reasonRequestSlice.reducer
