/* eslint-disable @typescript-eslint/dot-notation */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import axios from 'axios'
const axiosInstance = axios.create()

axiosInstance.interceptors.request.use((config: any) => {
  const token: string = window['userToken']
  if (token) {
    config['headers']['Authorization'] = `Bearer ${token}`
    config['headers']['Access-Control-Allow-Origin'] = '*'
  }
  return config
})

axiosInstance.defaults.baseURL = process.env.REACT_APP_BASE_URL_FORM

export default axiosInstance
