import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { Data as DataForm } from '_redux/slices/formSlice'

interface InitialState {
  loading: boolean
  result: DataForm | null
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: null,
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk('form/fetch', async (formId: number) => {
  return await axios
    .get('/forms/' + formId.toString())
    .then((response) => response.data.data)
})

const formGetSlice = createSlice({
  name: 'form',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetch.fulfilled,
      (state, action: PayloadAction<DataForm>) => {
        state.loading = false
        state.result = action.payload
        state.error = ''
      }
    )
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = null
      state.error = action.error.message ?? 'Something went wrong'
    })
  }
})

export default formGetSlice.reducer
