import axios from '_global/axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

interface PayloadData {
  statusCode: string
  message: string
  data?: any
}
export interface PermissionData {
  path: string
  view: boolean
  create: boolean
  edit: boolean
  delete: boolean
  viewDocs: boolean
}

export interface Data {
  id: number
  requestState: number
  permissionDetails: any
}
interface InitialState {
  loading: boolean
  permission: PermissionData[]
  roleId: number | null
  roleName: string
  error: string
}
const initialState: InitialState = {
  loading: false,
  permission: [],
  roleId: null,
  roleName: '',
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk(
  'userRol/fetch',
  async (rolId: string, { rejectWithValue }) => {
    try {
      return await axios
        .get('/permissiondetails/role/' + rolId)
        .then((response) => response.data)
    } catch (error) {
      return rejectWithValue(error.response.data.message)
    }
  }
)

const userRolSlice = createSlice({
  name: 'userRol',
  initialState,
  reducers: {
    reset: () => initialState
  },
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(
      fetch.fulfilled,
      (state, action: PayloadAction<PayloadData>) => {
        const obj = action.payload.data

        if (obj === undefined) {
          state.loading = false
          state.permission = []
          state.roleId = null
          state.roleName = ''
          state.error = action.payload.message ?? 'No existe el rol'
        } else {
          state.loading = false
          state.permission = obj.permissionDetails ?? []
          state.roleId = obj.roleId
          state.roleName = obj.description
          state.error = ''
        }
      }
    )
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.permission = []
      state.roleId = null
      state.roleName = ''
      let msg = ''
      if (typeof action.payload === 'string') {
        msg = action.payload
      } else if (Array.isArray(action.payload)) {
        msg = action.payload.join(', ')
      } else {
        msg = action.error.message ?? 'Something went wrong'
      }
      state.error = msg
    })
  }
})

export default userRolSlice.reducer

export const { reset } = userRolSlice.actions
