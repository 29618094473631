import axios from '_global/axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

export interface Data {
  id: number
  codeCountry: string
  countryId: number
  codeRetention: string
  description: string
  typeRetention: string
  priority: number
}
interface InitialState {
  loading: boolean
  result: Data[]
  resultKey: { [key: string]: Data }
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: [],
  resultKey: {},
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk('retentionType/fetch', async () => {
  return await axios.get('/retentions').then((response) => response.data.data)
})

const retentionTypeSlice = createSlice({
  name: 'retentionType',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<Data[]>) => {
      state.loading = false
      state.result = action.payload
      const temp: { [key: string]: Data } = {}
      action.payload.map((elem) => (temp[elem.id] = elem))
      state.resultKey = temp
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = []
      state.resultKey = {}
      state.error = action.error.message ?? 'Something went wrong'
    })
  }
})

export default retentionTypeSlice.reducer
