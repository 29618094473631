import axios from '_global/axios'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import i18next from 'i18next'
import { getLabelLang } from '_global/helperGlobal'

export interface Data {
  id: number
  codeCountry: string
  countryId: number
  codeCurrency: string
  description: string
  option: string
  description_lang?: string
}
interface InitialState {
  loading: boolean
  result: Data[]
  resultKey: { [key: string]: Data }
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: [],
  resultKey: {},
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk('currency/fetch', async () => {
  return await axios.get('/currencies').then((response) => response.data.data)
})

const currencySlice = createSlice({
  name: 'currency',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<Data[]>) => {
      state.loading = false
      const pl = action.payload.map((item) => ({
        ...item,
        description_lang: getLabelLang(item, 'description', i18next.language)
      }))
      state.result = pl
      const temp: { [key: string]: Data } = {}
      pl.map((elem) => (temp[elem.id] = elem))
      state.resultKey = temp
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = []
      state.resultKey = {}
      state.error = action.error.message ?? 'Something went wrong'
    })
  }
})

export default currencySlice.reducer
