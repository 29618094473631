import axios from '_global/axiosForm'
import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'

import { Data as DataField } from '_redux/slices/templateFileSlice'

interface Data {
  statusCode: string
  message: string
  data?: any
}
interface InitialState {
  loading: boolean
  result: Data | null
  error: string
}
const initialState: InitialState = {
  loading: false,
  result: null,
  error: ''
}

// Generates pending, fulfilled and rejected action types
export const fetch = createAsyncThunk(
  'templateFileEdit/fetch',
  async (
    {
      action,
      data,
      id
    }: {
      action: string
      data: DataField
      id: string
    },
    { rejectWithValue }
  ) => {
    const endpoint = 'file-field'
    try {
      switch (action) {
        case 'new':
          data = { ...data }
          return await axios
            .post(`/${endpoint}`, data)
            .then((response) => response.data)
        case 'edit':
          return await axios
            .patch(`/${endpoint}/${id}`, {
              fieldId: data.fieldId,
              fileIds: data.fileIds,
              title: data.title,
              title_en: data.title_en,
              description: data.description,
              description_en: data.description_en
            })
            .then((response) => response.data)
        case 'delete':
          return await axios
            .delete(`/${endpoint}/${id}`)
            .then((response) => response.data)
      }
    } catch (error) {
      return rejectWithValue(error.response.data.message)
    }
  }
)

const templateFileEditSlice = createSlice({
  name: 'templateFileEdit',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetch.pending, (state) => {
      state.loading = true
    })
    builder.addCase(fetch.fulfilled, (state, action: PayloadAction<Data>) => {
      state.loading = false
      state.result = action.payload
      state.error = ''
    })
    builder.addCase(fetch.rejected, (state, action) => {
      state.loading = false
      state.result = null
      let msg = ''
      if (typeof action.payload === 'string') {
        msg = action.payload
      } else if (Array.isArray(action.payload)) {
        msg = action.payload.join(', ')
      } else {
        msg = action.error.message ?? 'Something went wrong'
      }
      state.error = msg
    })
  }
})

export default templateFileEditSlice.reducer
