import React from 'react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import keycloak from './utils/configKeycloak'
import UserProvider from './utils/UserProvider'
import './App.css'

const keycloakActive = true

const App: React.FC = () => {
  if (!keycloakActive) {
    return <UserProvider />
  }
  return (
    <ReactKeycloakProvider
      initOptions={{ onLoad: 'login-required' }}
      authClient={keycloak}
      LoadingComponent={<div></div>}
    >
      <UserProvider />
    </ReactKeycloakProvider>
  )
}

export default App
